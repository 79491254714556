
export async function getAliOSS() {
    return new Promise(resolve => {
        if (window.OSS) {
            resolve(window.OSS)
        } else {
            var script = document.createElement("script");

            // script.src = "https://cdn01.xiaogj.com/uploads/x3/libs/official/oss/6.9.0/aliyun-oss-sdk.min.js"
            script.src = "https://cdn01.xiaogj.com/uploads/x3/libs/official/oss/6.17.0/aliyun-oss-sdk.min.js"
            script.onload = e => resolve(window.OSS)
            script.onerror = err => {
                throw new Error("加载 aliyun-oss-sdk 失败!")
            }

            window.document.head.appendChild(script);
        }
    })
}


export  async function getExifJs() {
    return new Promise(resolve => {
        if (window.EXIF) {
            resolve(window.EXIF)
        } else {
            Promise.all([getExif(), getIsRotate()]).then(res => {
                console.log("EXIF 类库加载成功");
                resolve(window.EXIF)
            })
        }
    })
}


function getExif() {
    return new Promise(resolve => {
        if (window.EXIF) {
            resolve(window.EXIF)
        } else {
            var script = document.createElement("script");
            script.src = "https://cdn01.xiaogj.com/uploads/x3/libs/official/exif/2.3.0/exif.min.js"
            script.onload = e => resolve(window.EXIF)
            script.onerror = err => {
                throw new Error("加载 EXIF.js 失败!")
            }
            window.document.head.appendChild(script);
        }
    })
}


// 判断 游览器是否会自动根据图片的 EXIF信息中的Orientation 进行旋转
// 如果游览器进行了旋转就不需要处理，没有旋转就需要进行旋转
// window.imgIsRotate  1 代表游览器做了旋转，0代表游览器没有做处理
function getIsRotate() {
    return new Promise(resolve => {
        if (window.imgIsRotate !== undefined) {
            resolve()
        } else {
            let img = document.createElement('img');
            img.src = 'https://cdn01.xiaogj.com/uploads/x3/assets/img/fix-rotate/fix-rotate-img.jpg';
            img.onload = function () {
                if (img.width && img.width > 300) {
                    window.imgIsRotate = 1;
                } else {
                    window.imgIsRotate = 0;
                }
                resolve();
            }
            img.onerror = err => {
                window.imgIsRotate = 0;
                resolve();
            }
        }
    })
}
